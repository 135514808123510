import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Header from "./header"
import "../components/layout.css"
import "../components/custom.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  FaLinkedin,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa"
import prolacsFooter from "../images/prolacs-logo-footer.png"
import konzekFooter from "../images/Konzek_Lite_white.svg"
import styled from "styled-components"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logoWhite: file(relativePath: { eq: "prolacs-logo-white.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoKonzek: file(relativePath: { eq: "konzek-logo-white.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const FooterMenuRow = styled(Row)`
    margin-top: 1rem;
  `
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <div className="prolacs-footer">
        <Container>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <Link to="/">
                {" "}
                <img
                  className="prolacs-footer-logo"
                  src={prolacsFooter}
                  alt="prolacs footer logo"
                />
              </Link>
            </Col>
          </Row>
          <FooterMenuRow>
            <Col sm className="footer-social-col ">
              <b>
                <p>Solutions</p>
              </b>
              <Link
                to="/solutions/smart-laundromat-control"
                style={{ fontSize: "15px !important" }}
              >
                Smart Laundromat Control
              </Link>
              <Link
                to="/solutions/laundry-management-system"
                style={{ fontSize: "15px !important" }}
              >
                Laundry Management System
              </Link>
            </Col>
            <Col sm className="footer-social-col">
              <b>
                <p>Products</p>
              </b>
              <Link to="/products" style={{ fontSize: "15px !important " }}>
                Tumble Washer Controllers
              </Link>
              <Link to="/products" style={{ fontSize: "15px !important" }}>
                Tumble Dryer Controllers
              </Link>
              <Link to="/products" style={{ fontSize: "15px !important" }}>
                Laundry 4.0 Smart Controllers
              </Link>
            </Col>
            <Col md className="footer-social-col ">
              <b>
                <p>Get in touch</p>
              </b>
              <a href="https://www.linkedin.com/company/prolacs">
                <FaLinkedin style={{ fontSize: "15px" }} />{" "}
              </a>
              <a href="https://www.twitter.com/prolacs">
                <FaTwitter style={{ fontSize: "15px" }} />{" "}
              </a>
              <a href="https://www.instagram.com/laundry4.0">
                <FaInstagram style={{ fontSize: "15px" }} />{" "}
              </a>
              <a href="mailto:info@prolacs.com">
                <FaEnvelope style={{ fontSize: "15px" }} />
              </a>{" "}
              <br />
              <div className="footer-social-wrapper">
                <a href="tel:+902163921642">
                  <FaPhone style={{ fontSize: "15px" }} /> +90 (216) 392 16 42{" "}
                </a>
                <a
                  href="https://www.google.com/maps/place/Konzek+Teknoloji+San.+ve+Tic.+A.%C5%9E./@40.9377464,29.1386308,17z/data=!3m1!4b1!4m6!3m5!1s0x14cadc43cc160e2f:0xcb5cb94b2a5db1a2!8m2!3d40.9377464!4d29.1412057!16s%2Fg%2F11crstrrs0?entry=ttu&g_ep=EgoyMDI1MDIxMi4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaMapMarkerAlt style={{ fontSize: "15px" }} /> Istanbul,
                  Turkey
                </a>
              </div>
            </Col>
          </FooterMenuRow>
          <Row style={{ textAlign: "center" }}>
            <Col sm className="footer-copy-title">
              <a href="https://konzek.com/" title="Konzek Teknoloji A.Ş.">
                {" "}
                <img
                  className="Konzek_Lite_white"
                  style={{ width: "5rem", marginBottom: "0rem" }}
                  src={konzekFooter}
                  alt="Konzek Teknoloji A.Ş. Logo"
                />
              </a>
              <br></br>
              <p className="footer-copy">
                {" "}
                All Rights Reserved, {new Date().getFullYear()}{" "}
              </p>
            </Col>
          </Row>
          <br></br>
        </Container>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
